<template>
    <div>
        <!-- Page Title Section -->
        <section class="page-title">
            <div class="pattern-layer-one" :style="{backgroundImage: 'url('+bgPattern5+')'}"></div>
            <div class="pattern-layer-two" :style="{backgroundImage: 'url('+bgPattern6+')'}"></div>
            <div class="icon-layer-one" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-two" :style="{backgroundImage: 'url('+icon5+')'}"></div>
            <div class="icon-layer-three" :style="{backgroundImage: 'url('+icon6+')'}"></div>
            <div class="icon-layer-four" :style="{backgroundImage: 'url('+iconPattern1+')'}"></div>
            <div class="icon-layer-five" :style="{backgroundImage: 'url('+iconPattern2+')'}"></div>
            <div class="auto-container">
                <h1>Real Estate</h1>
                <ul class="page-breadcrumb">
                    <li>
                        <router-link :to="{name: 'Home'}">home</router-link>
                    </li>
                    <li>Real Estate</li>
                </ul>
            </div>
        </section>
        <!-- End Page Title Section -->

        <div class="sidebar-page-container" style="margin-bottom: 150px">
            <div class="auto-container">
                <div class="row clearfix">

                    <!-- Content Side / Our Shop -->
                    <div class="content-side col-12">
                        <div class="our-shop">
                            <!--Sort By-->
                            <!--                            <div class="items-sorting">-->
                            <!--                                <div class="row clearfix">-->
                            <!--                                    <div class="results-column col-lg-8 col-md-6 col-sm-12">-->
                            <!--                                        <h5>Showing 1-9 of 12 results</h5>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="select-column pull-right col-lg-4 col-md-6 col-sm-12">-->
                            <!--                                        <div class="form-group">-->
                            <!--                                            <select name="sort-by">-->
                            <!--                                                <option>Default Sorting</option>-->
                            <!--                                                <option>By Order</option>-->
                            <!--                                                <option>By Price</option>-->
                            <!--                                            </select>-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </div>-->

                            <div class="row clearfix">
                                <!-- Shop Item -->
                                <div class="shop-item col-lg-4 col-md-6 col-sm-12">
                                    <div class="inner-box">
                                        <div class="image">
                                            <img src="../../../assets/images/estates/1/TE_Estate_ksjsvbjb.jpeg" alt="" />
                                            <ul class="options clearfix">
                                                <li>
                                                    <router-link :to="{name:'RealEstate1'}">
                                                        View
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <a href="mailto:mail@domain.com">
                                                        <small> Make an offer</small>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="lower-box pt-0">
                                            <div class="content">
                                                <h5>
                                                    <router-link :to="{name:'RealEstate1'}">
                                                        Escondido, CA 92027
                                                    </router-link>
                                                </h5>
                                                <div class="price">$749,500</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="shop-item col-lg-4 col-md-6 col-sm-12">
                                    <div class="inner-box">
                                        <div class="image">
                                            <img src="../../../assets/images/estates/2/TE_Estate_sjkfhkjhrj.jpeg" alt="" />
                                            <ul class="options clearfix">
                                                <li>
                                                    <router-link :to="{name:'RealEstate2'}">
                                                        View
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <a href="mailto:mail@domain.com">
                                                        <small> Make an offer</small>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="lower-box pt-0">
                                            <div class="content">
                                                <h5>
                                                    <router-link :to="{name:'RealEstate2'}">
                                                        380 Paradise Circle #B4
                                                        Woodland Park , CO 80863
                                                    </router-link>
                                                </h5>
                                                <div class="price">$199,500</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="shop-item col-lg-4 col-md-6 col-sm-12">
                                    <div class="inner-box">
                                        <div class="image">
                                            <img src="../../../assets/images/estates/3/TE_jfjdfbjdfjk.jpg" alt="" />
                                            <ul class="options clearfix">
                                                <li>
                                                    <router-link :to="{name:'RealEstate3'}">
                                                        View
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <a href="mailto:mail@domain.com">
                                                        <small> Make an offer</small>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="lower-box pt-0">
                                            <div class="content">
                                                <h5>
                                                    <router-link :to="{name:'RealEstate3'}">
                                                        BAY HEAD, NJ 08742
                                                    </router-link>
                                                </h5>
                                                <div class="price">$680,000</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!-- Post Share Options -->
                            <!--                            <div class="styled-pagination text-center">-->
                            <!--                                <ul class="clearfix">-->
                            <!--                                    <li class="prev"><a href="shop.html#"><span class="flaticon-left-arrow-1"></span> </a></li>-->
                            <!--                                    <li><a href="shop.html#">01</a></li>-->
                            <!--                                    <li class="active"><a href="shop.html#">02</a></li>-->
                            <!--                                    <li><a href="shop.html#">03</a></li>-->
                            <!--                                    <li><a href="shop.html#">04</a></li>-->
                            <!--                                    <li><a href="shop.html#">05</a></li>-->
                            <!--                                    <li><a href="shop.html#">06</a></li>-->
                            <!--                                    <li class="next"><a href="shop.html#"><span class="flaticon-right-arrow-1"></span> </a></li>-->
                            <!--                                </ul>-->
                            <!--                            </div>-->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import icon1 from "../../../assets/images/icons/icon-1.png";
import icon2 from "../../../assets/images/icons/icon-2.png";
import icon3 from "../../../assets/images/icons/icon-3.png";
import icon4 from "../../../assets/images/icons/icon-4.png";
import bgPattern5 from "../../../assets/images/background/pattern-5.png";
import bgPattern6 from "../../../assets/images/background/pattern-6.png";
import icon5 from "../../../assets/images/icons/icon-5.png";
import icon6 from "../../../assets/images/icons/icon-6.png";
import iconPattern1 from "../../../assets/images/icons/pattern-1.png";
import iconPattern2 from "../../../assets/images/icons/pattern-2.png";

export default {
    name: "Estates",
    data(){
        return {
            icon1, icon2, icon3, icon4,
            bgPattern5, bgPattern6,
            icon5, icon6, iconPattern1,
            iconPattern2,
        }
    }
}
</script>

<style scoped>

</style>